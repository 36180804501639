























import { InvestorPlatformClient, User } from '@/client'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class Trader extends Vue {
  isLoading = true
  isSetSubscribeLoading = false
  user: User | null = null

  get traderId() {
    return Number(this.$route.params.userId)
  }

  get isFollowingTrader() {
    return (
      this.user &&
      this.user.source_user_subscriptions &&
      this.user.source_user_subscriptions.find(s => s.subscribee_id == this.traderId) != null
    )
  }

  async mounted() {
    const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
    this.user = await client.getCurrentUser()
    this.isLoading = false
  }

  async setSubscription(subscribed: boolean) {
    this.isSetSubscribeLoading = true
    try {
      const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
      await client.setSubscription(this.traderId, subscribed)
      this.user = await client.getCurrentUser()
      await this.$router.push('/app')
      // TODO: notification
    } finally {
      this.isSetSubscribeLoading = false
    }
  }
}
